<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="university" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('university')">
                            <university-selectbox
                                :countryId="countryId"
                                :validateError="errors[0]"
                                :isCountryRequired="true"
                                v-model="updateData.university_id"></university-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              
                <b-col cols="12" class="d-flex justify-content-center">
                    
                    <b-button variant="primary" class="mt-0" @click="update">
                        {{ $t('save') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import UndergraduateTransferService from '@/services/UndergraduateTransferService'

    export default {
        name: "AcademicUnitOfficeUpdateForm",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            countryId: {
                type: Number,
                required: true
            },
            transferId: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                updateData: {
                    university_id: null
                },
            }
        },
        methods: {
            async update() {
                    const valid =  await this.$refs.formModalValidate.validate();
                    if(!valid) {
                        return
                    }
                    this.updateData.undergraduate_transfer_id = this.transferId
                    UndergraduateTransferService.updateUniversity(this.updateData)
                        .then((response) => {
                            const data = response.data.data
                            this.$emit('updated', data);
                            this.updateData = {university: null}
                        })
                        .catch((e) => {
                            this.showErrors(e)
                        })
              
            },
        },
    }
</script>
